/* Footer */
.footer {
  background: $footer-bg;
  color: $footer-color;
  padding: 20px 1rem;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration $action-transition-timing-function;
  -ms-transition: all $action-transition-duration $action-transition-timing-function;
  border-top: $border-width solid $border-color;
  font-size: calc(#{$default-font-size} - 0.05rem);
  font-family: $type-1;

  a {
    color: theme-color(success);
    font-size: inherit;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}