/* Tables */

.table {
  margin-bottom: 0;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type-2;
      font-weight: 500;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  td,
  th {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    white-space: nowrap;
    height: 35px;
    padding: $table-cell-padding;
    img {
      @extend .img-ss;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
    .form-check,
    .form-radio {
      margin-top: 0;
      margin-bottom: -0px;
    }
  }
  &.table-borderless {
    border: none;
    td,
    th,
    tr {
      border: none;
    }
  }
  &.table-bordered {
    thead {
      border: 1px solid $border-color;
      border-bottom: none;
      tr {
        th {
          border-left: none;
          border-right: none;
        }
      }
    }
    tbody {
      tr {
        td {}
      }
    }
  }
  &.table-striped {
    thead tr {
      background-color: $table-accent-bg;
    }
  }
}