@import "./mail-list-container";
@import "./messagecontent";

.email-wrapper {
    display: grid;
    grid-template-columns: 280px auto;
    margin-top: 30px;

    .mail-view {
        display: none;

        .mail-header-toolbar {
            display: flex;
            align-items: center;
            padding: 20px 10px;
        }
    }

    .email-view-wrapper {
        background: #fff;
        grid-column-start: 2;
        grid-column-end: 3;

        .mail-list-container {}
    }
}

@media (max-width: 960px) {
    .email-wrapper {
        grid-template-columns: 200px auto;
    }
}

@media (max-width: 768px) {
    .email-wrapper {
        display: block;
    }

    .mail-sidebar {
        position: fixed;
        top: 63px;
        right: -250px;
        bottom: 0px;
        z-index: 99;
        width: 250px;
        transition-duration: 0.6s;

        &.menu-open {
            left: 0;
        }
    }
}